import { LocationEnum } from "~/types/location/locationEnums.types";

export const locations: LocationEnum[] = [
  LocationEnum.CLUB,
  LocationEnum.DES_PRES,
  LocationEnum.NYC,
  LocationEnum.IBIZA,
  LocationEnum.HORS_LES_MURS,
];

export const locationToNames: { [key in LocationEnum]: string } = {
  CLUB: "Silencio",
  IBIZA: "El Silencio",
  HORS_LES_MURS: "Silencio Hors Les Murs",
  NYC: "Silencio NYC",
  DES_PRES: "Silencio Des Prés",
};

export const locationToColors: { [key in LocationEnum]: string } = {
  CLUB: "#AD9462",
  IBIZA: "#D8895D",
  HORS_LES_MURS: "#A2A1A1",
  NYC: "#FF0000",
  DES_PRES: "#4575D3",
};
