import "swiper/css";
import "swiper/css/pagination";

import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper/types";

import Img from "~/components/common/img";
import MainButton from "~/components/common/mainButton";
import AboutContact from "~/components/ui/contact";
import Filter from "~/components/ui/filter";
import NavLine from "~/components/ui/navLine";
import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";
import { LocationEnum } from "~/types/location/locationEnums.types";
import { Venue } from "~/types/venue/venue.types";
import {
  locations,
  locationToColors,
  locationToNames,
} from "~/utils/constants/locations";

import styles from "./index.module.scss";

interface Props {
  venues: Venue[];
}

type RouterType = {
  query?: {
    selected?: LocationEnum;
  };
};

const breakpoints = {
  1: {
    slidesPerView: 1.5,
    spaceBetween: 0,
  },
  480: {
    slidesPerView: 2,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
  1550: {
    slidesPerView: 3.5,
    spaceBetween: 0,
  },
  1800: {
    slidesPerView: 4,
    spaceBetween: 0,
  },
};

const reservationUrl: Record<
  Exclude<LocationEnum, LocationEnum.HORS_LES_MURS>,
  string
> = {
  [LocationEnum.CLUB]: "mailto:contact@silencio-club.com",
  [LocationEnum.DES_PRES]:
    "https://bookings.zenchef.com/results?rid=352396&pid=1001",
  [LocationEnum.IBIZA]:
    "https://widget.thefork.com/fr/52eb04a2-517b-4688-b0d8-64cb4aec166c/homepage/6102e518-a682-40c0-af44-2f2ba7fb2107?origin=facebook&utm_medium=integration&utm_source=instagram",
  [LocationEnum.NYC]: "mailto:contact@silencionyc.com",
};

const VenuesDisplay = ({ venues: staticVenueProp }: Props) => {
  const [filter, setFilter] = useState(LocationEnum.CLUB);
  const [selectNav, setSelectNav] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType.Swiper>();

  const { language } = useLanguage();
  const router: RouterType = useRouter();

  const venues = useMemo(() => {
    // create an object with keys of the location enum from venues and value of the venue
    return staticVenueProp.reduce((acc, venue) => {
      acc[venue.location] = venue;
      return acc;
    }, {} as Record<LocationEnum, Venue>);
  }, [staticVenueProp]);

  const corpsTexts =
    language === "En"
      ? englishTranslations.homePage.corps
      : frenchTranslations.homePage.corps;

  const handleSetFilter = (filterName: LocationEnum) => {
    setFilter(filterName);
    setSelectNav(0);
  };

  useEffect(() => {
    if (router?.query?.selected) {
      if (Object.values(LocationEnum).includes(router.query.selected))
        setFilter(router.query.selected);
      setSelectNav(0);
    }
  }, [router.query]);

  return (
    <>
      <Filter<LocationEnum>
        items={locations.filter((location) => venues[location])}
        itemsColor={locations.map((location) => locationToColors[location])}
        filteredItems={[filter]}
        handleSetFilter={handleSetFilter}
        className={styles.filter}
        mapToName={(location) => locationToNames[location]}
      />

      <section className="p-1">
        <p className="description">
          {venues[filter] && venues[filter][`description${language}`]}
        </p>
        <div className="center p-1">
          {venues[filter] && venues[filter].menu && (
            <a href={venues[filter].menu} target="_blank" rel="noreferrer">
              <MainButton theme="white">
                Menu <RiLogoutBoxRLine />
              </MainButton>
            </a>
          )}
          {filter !== LocationEnum.HORS_LES_MURS && (
            <a href={reservationUrl[filter]} target="_blank" rel="noreferrer">
              <MainButton theme="white">
                {corpsTexts.bookNow} <RiLogoutBoxRLine />
              </MainButton>
            </a>
          )}
        </div>
      </section>

      <Swiper
        slidesPerView={3.5}
        spaceBetween={0}
        centeredSlides={true}
        loop
        loopFillGroupWithBlank={true}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        className="mySwiper"
        preloadImages
        loopAdditionalSlides={50}
        onClick={(swiper) => {
          swiper.slideTo(swiper.clickedIndex);
        }}
        breakpoints={breakpoints}
      >
        {venues[filter] &&
          venues[filter].venueLocations.map((venueLocation, i) => {
            const venueName = venueLocation[`name${language}`];

            return (
              <SwiperSlide
                onClick={() => {
                  setSelectNav(i);
                }}
                key={i}
                className={styles.slider}
              >
                <Img
                  key={venueLocation.id}
                  src={venueLocation.picturePath}
                  alt={venueName}
                  lazyLoad={false}
                />
                <MainButton
                  theme={selectNav === i ? undefined : "transparent-grey"}
                  className={styles.eventLocationNav}
                >
                  {venueName}
                </MainButton>
              </SwiperSlide>
            );
          })}
      </Swiper>

      <div className={[styles.scrollContainer, "no-scrollbar"].join(" ")}>
        <NavLine
          selected={selectNav}
          items={
            venues[filter] &&
            venues[filter].venueLocations.map((location) =>
              language === "Fr" ? location.nameFr : location.nameEn
            )
          }
          divider={"→"}
          onClickButton={(index: number) => {
            setSelectNav(index);
            if (swiperInstance) {
              const differenceBetweenIndexes =
                (swiperInstance.activeIndex %
                  (venues[filter] && venues[filter].venueLocations.length)) -
                index;
              const slideToIndex =
                swiperInstance.activeIndex - differenceBetweenIndexes;
              swiperInstance.slideTo(slideToIndex);
            }
          }}
        />
      </div>

      <section className="p-1">
        <div className="description">
          {!!(venues[filter] && venues[filter].venueLocations[selectNav]) &&
            venues[filter].venueLocations[selectNav][`description${language}`]}
        </div>
      </section>
      <AboutContact contactInfo={venues} outsideFilter={filter} />
    </>
  );
};

export default VenuesDisplay;
