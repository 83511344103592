import type { GetStaticProps } from "next";

import { getAllVenuesWithLocations } from "~/api/venues/getAllVenuesWithLocations";
import Spacer from "~/components/common/spacer";
import SEO from "~/components/SEO";
import VenuesDisplay from "~/components/venues/display";
import VenuesIntro from "~/components/venues/intro";
import { Venue } from "~/types/venue/venue.types";

interface Props {
  venues: Venue[];
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const venues = await getAllVenuesWithLocations();
  return { props: { venues } };
};

const Venues = ({ venues }: Props) => {
  return (
    <>
      <SEO
        title="Silencio - Nos lieux"
        description="Restaurant, dancing, bar à cocktail, salle de cinéma, boudoir, salle de concert, en ville ou les pieds dans l’eau..."
        nofollow={false}
        noindex={false}
      />
      <Spacer width={"100%"} height={"150px"} />
      <VenuesIntro />
      <VenuesDisplay venues={venues} />
    </>
  );
};

export default Venues;
