import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";

const VenuesIntro = () => {
  const { language } = useLanguage();

  const aboutText =
    language === "En"
      ? englishTranslations.venuesPage.intro
      : frenchTranslations.venuesPage.intro;
  return (
    <section className="p-1 bbg">
      {/* <h5 className="small-title">À propos du Silencio</h5> */}
      <p className="description">{aboutText}</p>
    </section>
  );
};

export default VenuesIntro;
