import React, { DetailedHTMLProps, Fragment, HTMLAttributes } from "react";

import MainButton from "../../common/mainButton";
import styles from "./index.module.scss";

type Props<T extends string | React.ReactNode> = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  items: T[];
  selected?: number | null;
  divider?: T;
  onClickButton?: (i: number) => void;
};

const NavLine = <T extends string | React.ReactNode>({
  items,
  selected = null,
  className,
  divider,
  onClickButton = () => {},
}: Props<T>) => {
  return (
    <div className={[styles.container, className].join(" ")}>
      {items &&
        items.map((item, i) => (
          <Fragment key={i}>
            <MainButton
              onClick={() => {
                onClickButton(i);
              }}
              className={[
                styles.item,
                selected === i ? styles.selected : null,
              ].join(" ")}
            >
              {item}
            </MainButton>
            {divider ? (i === items.length - 1 ? null : divider) : null}
          </Fragment>
        ))}
    </div>
  );
};

export default NavLine;
