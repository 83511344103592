import { useState } from "react";

import AsperandIcon from "~/components/icons/asperand.icon";
import InstagramIcon from "~/components/icons/instagram.icon";
import LocationIcon from "~/components/icons/location.icon";
import PhoneIcon from "~/components/icons/phone.icon";
import { LocationEnum } from "~/types/location/locationEnums.types";
import { VenueContactInfo } from "~/types/venue/venue.types";
import { locationToColors, locationToNames } from "~/utils/constants/locations";
import { getObjectKeys } from "~/utils/functions/general.functions";

import Filter from "../filter";
import styles from "./index.module.scss";

interface Props {
  contactInfo: Record<LocationEnum, VenueContactInfo>;
  outsideFilter?: LocationEnum;
}

const AboutContact = ({ contactInfo, outsideFilter }: Props) => {
  const [filter, setFilter] = useState<LocationEnum>(LocationEnum.CLUB);

  const handleSetFilter = (location: LocationEnum) => {
    setFilter(location);
  };

  const {
    street,
    postalCode,
    city,
    country,
    phoneNumber,
    email,
    googleMapLocation,
    instagram,
  } = contactInfo[outsideFilter ?? filter];

  const locations = getObjectKeys(contactInfo);

  return (
    <section className={styles.aboutContact}>
      {!outsideFilter && (
        <Filter<LocationEnum>
          items={locations}
          hiddenItems={[LocationEnum.HORS_LES_MURS]}
          itemsColor={locations.map((location) => locationToColors[location])}
          filteredItems={[filter]}
          handleSetFilter={handleSetFilter}
          className={styles.filter}
          mapToName={(location) => locationToNames[location]}
        />
      )}

      <address className={styles.contactInformation}>
        <div>
          <div>
            {street},<br />
            {postalCode} {city}, {country}
          </div>
          <div className={styles.externalUrls}>
            <a href={`tel:${phoneNumber}`}>
              <PhoneIcon />
            </a>
            <a href={`mailto:${email}`}>
              <AsperandIcon />
            </a>
            <a href={googleMapLocation} target="_blank" rel="noreferrer">
              <LocationIcon />
            </a>
            <a href={instagram} target="_blank" rel="noreferrer">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </address>
    </section>
  );
};

export default AboutContact;
