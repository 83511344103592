import { useRouter } from "next/router";
import { DetailedHTMLProps, HTMLAttributes, useEffect } from "react";

import MainButton from "../../common/mainButton";
import styles from "./index.module.scss";

type Props<T> = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  items: T[];
  hiddenItems?: T[];
  itemsColor?: string[];
  filteredItems: T[];
  relative?: boolean;
  handleSetFilter: (filter: T) => void;
  mapToName: (item: T) => string;
};

const Filter = <T,>({
  mapToName,
  itemsColor,
  items,
  hiddenItems,
  filteredItems,
  className,
  relative = true,
  handleSetFilter,
}: Props<T>) => {
  const filteredItemsSet = hiddenItems
    ? items.filter((item) => !hiddenItems || !hiddenItems?.includes(item))
    : items;

  const { query } = useRouter();

  useEffect(() => {
    if (query.tab) {
      filteredItemsSet.map((item) => {
        if (mapToName(item) === query.tab) {
          handleSetFilter(item);
        }
      });
    }
  }, [query]);

  return (
    <div
      className={[
        relative ? styles.relativeFilter : styles.genericFilter,
        className,
      ].join(" ")}
    >
      {filteredItemsSet.map((item, i) => (
        <MainButton
          key={i}
          className={[
            styles.item,
            filteredItems.includes(item) ? styles.selected : null,
          ].join(" ")}
          onClick={() => {
            handleSetFilter(item);
          }}
          style={itemsColor?.[i] ? { color: itemsColor?.[i] } : {}}
        >
          {mapToName(item)}
        </MainButton>
      ))}
    </div>
  );
};

export default Filter;
