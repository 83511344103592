import type { PropsWithChildren } from "react";

type Props = {
  width: string;
  height: string;
};

const Spacer = ({ width, height }: PropsWithChildren<Props>) => {
  return <div style={{ width: width, height: height }} />;
};

export default Spacer;
